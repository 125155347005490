import React from 'react';
import { bool, func } from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import MobileStepper from '@material-ui/core/MobileStepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import StepConnector from '@material-ui/core/StepConnector';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import CompleteStepIcon from '../common/Icons/CompleteStepIcon'
import IncompleteStepIcon from '../common/Icons/IncompleteStepIcon'

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: "#1160F7",
    },
  },
  completed: {
    '& $line': {
      borderColor: '#1160F7 ',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useCustomStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 15,
    alignItems: 'center',
  },
  active: {
    color: '#1160F7',
  },
  completed: {
    color: '#1160F7',
    zIndex: 1,
    //fontSize: 18,
  },
  incompleted: {
    color: 'grey'
  }
});

function CustomStepIcon(props) {
  const classes = useCustomStepIconStyles();
  let { active, completed, onClickStep, allActive } = props;
  if (allActive) active=true;

  return (
    <StepButton disabled={onClickStep && completed ? false : true} onClick={onClickStep}>
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}>
        {completed
          ? <CompleteStepIcon />
          : <IncompleteStepIcon disabled={!active} className={classes.incompleted} />
        }
      </div>
    </StepButton>

  );
}

CustomStepIcon.propTypes = {
  active: bool,
  allActive: bool,
  completed: bool,
};

const getStepIcon = ({allActive}) => {
  if (allActive) return <CustomStepIcon allActive />
  return CustomStepIcon
}

const useStyles = makeStyles(theme => ({
  root: {
    // height: 'auto',
    // background: 'transparent',
    // justifyContent: 'center',
    // width: '100%'
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  mobileContainer: {
    position: 'absolute', 
    left: 0, 
    right: 0, 
    marginLeft: 'auto', 
    marginRight: 'auto', 
    maxWidth: '200px'
  },
  normalContainer: {
    position: 'absolute', 
    left: 0, 
    right: 0, 
    marginLeft: 'auto', 
    marginRight: 'auto'
  }
}));

function HeaderStepper({
  steps,
  activeStep,
  onClickStep,
  // rootStyle,
  responsive,
  alternativeLabel = true,
  allActive
}) {
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (responsive && fullScreen) {
    return (
        <Container className={classes.mobileContainer}>
          
        <MobileStepper
          variant="progress"
          steps={steps.length}
          position="static"
          activeStep={activeStep}
          className={classes.root}
          LinearProgressProps={{style: { width: '100%' }}}
        />

        </Container>
    )
  }

  return (
      <Container maxWidth='sm' className={classes.normalContainer}>
      <Stepper style={{ background: 'transparent' }} alternativeLabel={alternativeLabel} activeStep={activeStep} connector={<CustomConnector />}>
        {steps.map(({ Label, key }) => (
          <Step key={key}>
            <StepLabel 
              StepIconProps={{ onClickStep: () => onClickStep && onClickStep(key) }}
              StepIconComponent={getStepIcon({allActive})}
            > 
              <Label />
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      </Container>
  );
}

HeaderStepper.defaultProps = {
  steps: [],
  allActive: false,
}

HeaderStepper.propTypes = {
  onClickStep: func,
  responsive: bool,
  allActive: bool,
}

export default HeaderStepper;