import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
    number, oneOf, string
} from 'prop-types';
import React from 'react';

const StatusIcon = ({
  width,
  height,
  mobileWidth,
  mobileHeight,
  variant,
  outsideCircleColor,
  insideCircleColor,
}) => {
  // eslint-disable-next-line default-case
  let contentIcon;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let size = {
    width,
    height
  };
  if(isMobile) {
    size = {
      width: mobileWidth || 80,
      height: mobileHeight || 80,
    }
  }

  if (variant === 'error') {
    contentIcon = (
      <svg xmlns="https://www.w3.org/2000/svg" {...size} viewBox="0 0 144.296 144.296">
        <g id="Group_4065" data-name="Group 4065" transform="translate(-275 -372)">
          <path id="Path_1346" data-name="Path 1346" d="M72.148,0A72.148,72.148,0,1,1,0,72.148,72.148,72.148,0,0,1,72.148,0Z" transform="translate(275 372)" fill="rgba(255,75,85,0.42)" />
          <g id="error" transform="translate(298.049 395.049)">
            <g id="Group_527" data-name="Group 527" transform="translate(0 0)">
              <path id="Path_1323" data-name="Path 1323" d="M49.1,0A49.1,49.1,0,1,0,98.2,49.1,49.153,49.153,0,0,0,49.1,0Zm0,0" fill="#ff4b55" />
              <path id="Path_1324" data-name="Path 1324" d="M242.851,366.759a4.091,4.091,0,1,1-4.091-4.091A4.091,4.091,0,0,1,242.851,366.759Zm0,0" transform="translate(-189.661 -293.112)" fill="#eceff1" />
              <path id="Path_1325" data-name="Path 1325" d="M242.851,143.492a4.091,4.091,0,0,1-8.183,0V110.759a4.091,4.091,0,0,1,8.183,0Zm0,0" transform="translate(-189.661 -86.21)" fill="#fafafa" />
            </g>
          </g>
        </g>
      </svg>
    );
  } else if (variant === 'warning') {
    contentIcon = (
      <svg xmlns="https://www.w3.org/2000/svg" {...size} viewBox="0 0 144.296 144.296">
        <g id="Group_4065" data-name="Group 4065" transform="translate(-275 -372)">
          <path id="Path_1346" data-name="Path 1346" d="M72.148,0A72.148,72.148,0,1,1,0,72.148,72.148,72.148,0,0,1,72.148,0Z" transform="translate(275 372)" fill="rgba(250,201,72,0.42)" />
          <g id="warning" transform="translate(298.049 395.049)">
            <g id="Group_527" data-name="Group 527" transform="translate(0 0)">
              <path id="Path_1323" data-name="Path 1323" d="M49.1,0A49.1,49.1,0,1,0,98.2,49.1,49.153,49.153,0,0,0,49.1,0Zm0,0" fill="#FAC948" />
              <path id="Path_1324" data-name="Path 1324" d="M242.851,366.759a4.091,4.091,0,1,1-4.091-4.091A4.091,4.091,0,0,1,242.851,366.759Zm0,0" transform="translate(-189.661 -293.112)" fill="#eceff1" />
              <path id="Path_1325" data-name="Path 1325" d="M242.851,143.492a4.091,4.091,0,0,1-8.183,0V110.759a4.091,4.091,0,0,1,8.183,0Zm0,0" transform="translate(-189.661 -86.21)" fill="#fafafa" />
            </g>
          </g>
        </g>
      </svg>
    );
  } else {
    contentIcon = (
      <svg id="Group_1669" data-name="Group 1669" xmlns="https://www.w3.org/2000/svg" {...size} viewBox="0 0 96.983 96.983">
        <circle id="Ellipse_364" data-name="Ellipse 364" cx="48.492" cy="48.492" r="48.492" transform="translate(0)" fill={outsideCircleColor} />
        <circle id="Ellipse_339" data-name="Ellipse 339" cx="34.745" cy="34.745" r="34.745" transform="translate(13.747 13.747)" fill={insideCircleColor} />
        <g id="verify-sign_1_" data-name="verify-sign (1)" transform="translate(28.559 34.016)">
          <g id="tick" transform="translate(0 0)">
            <g id="Group_278" data-name="Group 278">
              <path id="Path_950" data-name="Path 950" d="M34.941,71.877a3.886,3.886,0,0,0-5.5,0L11.771,90.625,2.335,82.232a3.836,3.836,0,0,0-5.424,0,3.9,3.9,0,0,0-.459,4.87,3.249,3.249,0,0,0,.459.554l12.473,11a3.836,3.836,0,0,0,5.424,0,3.876,3.876,0,0,0,.4-.484c.022-.021.047-.035.069-.056L34.941,77.372A3.887,3.887,0,0,0,34.941,71.877Z" transform="translate(4.187 -70.738)" fill="#fff" />
            </g>
          </g>
        </g>
      </svg>
    );
  }

  return contentIcon;
};

StatusIcon.propTypes = {
  width: number,
  height: number,
  insideCircleColor: string,
  outsideCircleColor: string,
  status: oneOf(['success', 'warning', 'error']),
};

StatusIcon.defaultProps = {
  width: 126.983,
  height: 126.983,
  insideCircleColor: '#2EBC31',
  outsideCircleColor: 'rgba(75,197,78,0.4)',
  status: 'success',
};

export default StatusIcon;
