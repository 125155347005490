import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    DialogActions,
    Button,
    IconButton,
    Box,
  } from '@material-ui/core';
  import React, { useState } from 'react';
  import CloseIcon from '@material-ui/icons/Close';
  import { makeStyles } from '@material-ui/core/styles';
  import TextField from './TextField';
  import { saveCustomerRequest } from '../../redux/auth/actions';
  import PropTypes from 'prop-types';
  import { useTranslation } from 'next-i18next';
  import StatusDialog from './dialogs/statusDialog/StatusDialogV2';
  
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(4, 3),
      maxWidth: 500,
    },
    closeIcon: {
      color: '#1160F7',
      float: 'right',
      display: 'flex',
      position: 'relative',
      zIndex: '9999',
    },
    title: {
      color: '#31517A',
      fontFamily: 'Poppins',
      fontSize: '24px',
      lineHeight: '36px',
      fontWeight: 600,
    },
    description: {
      paddingTop: theme.spacing(2),
      color: '#626680',
      fontSize: '14px',
      lineHeight: '20x',
    },
    dialogTitle: {
      borderBottom: '1px solid #CACCD5',
      padding: theme.spacing(0, 0, 2),
    },
    content: {
      padding: theme.spacing(3, 0, 0),
    },
    button: {
      height: '56px',
      width: '100%',
      fontSize: '18px',
      fontWeight: 500,
      padding: '15px 0',
    },
    buttonDisabled: {
      backgroundColor: '#CACCD5 !important',
      color: '#fff !important',
    },
  }));
  
  const RequestDialog = ({ open, handleClose, request_type }) => {
    const classes = useStyles();
    const { t } = useTranslation('landing');
    const [submitted, setSubmitted] = useState(false);
    const [values, setValues] = useState({
      request_type : request_type,
      data: '',
      email: ''
    });
  
    const handleChange = (value, name) => {
      setValues({ ...values, [name]: value });
    };
  
    const handleSubmit = async () => {
      await saveCustomerRequest(values.email, values.data, values.request_type ? values.request_type.toUpperCase() : null);
      setSubmitted(true);
    };
  
    const close = () => {
      setValues({
        data: '',
        email: '',
      });
      setSubmitted(false);
      handleClose(false);
    };
  
    return (
      <Dialog className="requestDialog" open={open} classes={{ paper: classes.root }} onClose={close}>
        {!submitted && (
          <>
            <DialogTitle id="request_dialog" className={classes.dialogTitle}>
              <IconButton size="small" className={classes.closeIcon} onClick={close}>
                <CloseIcon />
              </IconButton>
  
              <Typography variant="h4" className={classes.title}>
                {t(`request_dialog.${request_type}_title`)}
              </Typography>
  
              <Typography className={classes.description}>
              {t(`request_dialog.${request_type}_description`)}
              </Typography>
            </DialogTitle>
  
            <DialogContent className={classes.content}>
              <TextField
                label={t(`request_dialog.${request_type}_label`)}
                value={values.data}
                onChange={(e) => handleChange(e.target.value, 'data')}
                placeholder={t(`request_dialog.${request_type}_placeholder`)}
              />
              <Box pt={3} />
              <TextField
                label={'Email address'}
                value={values.email}
                onChange={(e) => handleChange(e.target.value, 'email')}
                placeholder={'Enter email address'}
              />
            </DialogContent>
            <DialogActions className={classes.content}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                id="request_dialog"
                disabled={
                  values.data === '' ||
                  !values.email.includes('@') ||
                  !values.email.includes('.co')
                }
                classes={{ root: classes.button, disabled: classes.buttonDisabled }}
                fullWidth
              >
                {'Submit'}
              </Button>
            </DialogActions>
          </>
        )}
        {submitted && (
          <StatusDialog
            title={'Success!'}
            description={`We received your request and will send you an email with bonus once it is available.`}
            buttonText={`Got it!`}
            status={'success'}
            onCloseHandler={() => { close() }}
            open={true}
          />
        )}
      </Dialog>
    );
  };
  
  RequestDialog.propTypes = {
    open: PropTypes.bool,
    request_type: PropTypes.string,
    handleClose: PropTypes.func,
  };
  
  RequestDialog.defaultProps = {
    request_type: 'country'
  };
  
  export default RequestDialog;
  