import { Toolbar } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { IconButton, Button } from "@material-ui/core";
import { AppBar } from "@material-ui/core";
import CustomStepper from "./HeaderStepper";
import CloseIcon from '@material-ui/icons/Close';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useRouter } from "next/router";
import Logo from "./Logo";

const useStyles = makeStyles(theme => ({
    appBar: {
        background: 'white',
        padding: theme.spacing(3, 0),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: '1px solid #E9E9E9'
    },
    mobileAppBar: {
        marginBottom: 0,
        height: 80,
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: '1px solid #E9E9E9'
    },
    relative: {
        position: 'relative',
    },
    centered: {
        margin: '0 auto'
    },
    logo: {
        width: '100%',
        maxWidth: '120px'
    },
    mobileLogo: {
        width: '36px'
    },
    closeButton: {
        marginRight: 0,
        marginLeft: 'auto'
    },
    toolbar: {
        padding: 0
    }
}));

const CommonHeader = ({ steps, onClickStep, activeStep, hideProgress, hideBack, hideBackText, processing = false, onClose, customBack, disableBackButton, absolutePosition, isStepLabelSeparate = true }) => {
    const classes = useStyles();
    const theme = useTheme();
    const router = useRouter();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleBack = () => {
        if (customBack) {
            customBack();
            return
        }
        if (activeStep === 0) {
            router.back();
            return
        }
        onClickStep(activeStep - 1);
    };

    const handleClose = () => {
        if (onClose) {
            onClose();
            return
        }
        router.push('/dashboard');
    }

    return (
        <AppBar className={[fullScreen ? classes.mobileAppBar : classes.appBar, !absolutePosition && classes.relative]} elevation={0}>
            <Container maxWidth='lg' className={classes.centered}>
                <Toolbar className={classes.toolbar}>
                    
                    {!hideBack && <Button onClick={handleBack} size="small" color="primary" alignSelf="flex-start" disabled={disableBackButton}>
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : !hideBackText ? <KeyboardArrowLeft /> : <KeyboardBackspaceIcon/> }
                        {!hideBackText && <>Back</>}
                    </Button>}

                    {hideBack && <>
                        {!fullScreen ? <Logo className={classes.logo} /> : <img src='/img/splash-logo.svg' className={classes.mobileLogo} />}
                    </>}
                    
                    {!hideProgress && <CustomStepper 
                        onClickStep={onClickStep}
                        steps={steps}
                        activeStep={activeStep}
                        alternativeLabel={isStepLabelSeparate}
                        responsive
                    />}

                    {!processing && (
                        <IconButton className={classes.closeButton} onClick={handleClose}>
                            <CloseIcon color="primary"/>
                        </IconButton>
                    )}

                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default CommonHeader;